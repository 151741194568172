import Vue from 'vue'
import App from './App.vue'
import router from './router'

Vue.config.productionTip = false

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import './assets/base.css';
// import './assets/themes.scss';
// import './assets/index.scss'
import 'animate.css'
import wow from 'wowjs'
import 'wowjs/css/libs/animate.css';
Vue.prototype.$wow = wow




new Vue({
	router,
	render: h => h(App)
}).$mount('#app')