import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [{

		path: "/",
		name: "layout",
		component: () => import( /* webpackChunkName: "layout" */ '@/components/layout'),
		redirect: '/home',
		children: [{
			path: '/home',
			name: 'about',
			component: () => import( /* webpackChunkName: "about" */ '@/views/home.vue')
		}, {
			path: '/about',
			name: 'about',
			component: () => import( /* webpackChunkName: "about" */ '@/views/aboutUs.vue')
		}, {
			path: '/articleDetail',
			name: 'articleDetail',
			component: () => import( /* webpackChunkName: "about" */ '@/views/article/articleDetail.vue'),
			meta: {
				title: ""
			}
			
		}, {
			path: '/articlelist',
			name: 'articlelist',
			component: () => import( /* webpackChunkName: "about" */ '@/views/article/articlelist.vue')
		}]
	},

]

const router = new VueRouter({
	routes
})

export default router